import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

export default ({ data, location }) => {
  const heroImage = data.heroImage
  const industries = data.industries.edges
  const platforms = data.platforms.edges
  const legal = data.legal.edges
  const services = data.services.edges
  const news = data.news.edges
  const blog = data.blog.edges

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title="UNICOM Engineering - Sitemap"
          description="Use this sitemap to find the content you're looking for and then navigate your way around the UNICOM Engineering website."
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp.fluid.src}
        />
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.fluid}
          title="UNICOM Engineering, Inc. sitemap"
          subtitle={null}
          type="sitemap"
        />
      </div>
      <section className="bg-white">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="8">
                <MDBRow>
                  <MDBCol md="6" className="mb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5">
                      Home 
                      <Link to="/" className="ml-2 linkedin"> 
                        <FontAwesomeIcon icon={faLink} /> 
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5">
                      Company
                      <Link to="/about/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <p className="font-w-400 text-medium">
                      <Link to="/about/" className="effect-noline">
                        About Us
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/careers/"
                        className="effect-noline"
                      >
                        Careers
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/contact/"
                        className="effect-noline"
                      >
                        Contact Us
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/about/leadership/"
                        className="effect-noline"
                      >
                        Leadership
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/quality/"
                        className="effect-noline"
                      >
                        Quality
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/resources/"
                        className="effect-noline"
                      >
                        Resources
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/support/"
                        className="effect-noline"
                      >
                        Support
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link
                        to="/technology-partners/"
                        className="effect-noline"
                      >
                        Technology Partners
                      </Link>
                    </p>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-5">
                      AI
                      <Link to="/ai/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>                    
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-5">
                      Hybrid Cloud
                      <Link to="/industries/microsoft-azurestack/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-5">
                      Liquid cooling
                      <Link to="/liquid-cooling/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-5">
                      Storage
                      <Link to="/storage/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      UNICOM Global Divisions
                      <Link to="/unicom-global/divisions/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>                    
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Search
                      <Link to="/search/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Privacy &amp; legal
                    </h2>
                    {legal.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline">
                          {properCase(node.frontmatter.title)}
                        </Link>
                      </p>
                    ))}
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Newsroom
                      <Link to="/news/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    {news.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link
                          to={`/news/${node.slug + `/`}`}
                          className="effect-noline"
                        >
                          {properCase(node.title)}
                        </Link>
                      </p>
                    ))}
                  </MDBCol>

                  <MDBCol md="6" className="mb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                      Industries
                      <Link to="/industries/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    {industries.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline">
                          {properCase(node.frontmatter.name)}
                        </Link>
                      </p>
                    ))}
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Services
                      <Link to="/services/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    {services.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline">
                          {properCase(node.frontmatter.title)}
                        </Link>
                      </p>
                    ))}
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Blog
                      <Link to="/blog/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    {blog.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline">
                          {properCase(node.frontmatter.title)}
                        </Link>
                      </p>
                    ))}
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol md="4" className="mb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                  Platforms
                  <Link to="/platforms/" className="ml-2 linkedin">
                    <FontAwesomeIcon icon={faLink} />
                  </Link>
                </h2>
                {platforms.map(({ node }, index) => (
                  <p className="font-w-400 text-medium" key={index}>
                    <Link to={node.fields.slug} className="effect-noline">
                      {properCase(node.frontmatter.title)}
                    </Link>
                  </p>
                ))}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query SitemapQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    industries: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "industries" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }
    platforms: allMarkdownRemark(
      filter: {frontmatter: {template: {eq: "platforms"}, suppresspage: {ne: true}}},
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "services" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    blog: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    news: allContentfulNews(
      filter: {
        publishTo: {
          elemMatch: { url: { eq: "https://www.unicomengineering.com" } }
        }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
